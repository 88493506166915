import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import JsPDF from 'jspdf';
import QRCode from 'qrcode';
import dayjs from 'dayjs';
import { useParams, useSearchParams } from 'react-router-dom';
import { Col, Row, Result, Popover, Space } from 'antd';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { PrinterOutlined, PaperClipOutlined } from '@ant-design/icons';
import styles from './memberCertificate.module.css';
import { getUserCertificate } from '../../services/services';
import signature from '../../assets/certSignature.png';
import download from '../../assets/download.png';
import nsrnCertificateFooter from '../../assets/nsrnCertificateFooter.png';
import nsrnCertificateBack from '../../assets/nsrnCertificateBack.png';
import { CompanyInterface } from '../../models/companyInterface';
import config from '../../config';
import { getMemberCategoriesLabelsEnum, getPartnerCategoriesLabelsEnum } from '../../utils/utils';
import { UserCategory } from '../../consts/UserCategoryEnum';
import { PartnersEnum } from '../../consts/UserSubcategories';
import { ReactComponent as Logo } from '../../assets/nsrnLogo.svg';

const MemberCertificate = (): ReactElement => {
  const { t } = useTranslation('common');
  const [qr, setQr] = useState<string | null>(null);
  const [companyData, setCompanyData] = useState<CompanyInterface>();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  const CategTag = ({ children }: { children: ReactNode }): ReactElement => (
    <span className={styles.redColor}>{children}</span>
  );

  const getCategoriesText = (): ReactElement => {
    let categories: ReactElement[] = [];
    const memberCategories = getMemberCategoriesLabelsEnum(t);
    const partnerCategories = getPartnerCategoriesLabelsEnum(t);
    if (companyData?.userCategory === UserCategory.MEMBER) {
      categories = (companyData.memberCategories as string[]).map((category) => (
        <CategTag key={category}>{memberCategories[category]}</CategTag>
      ));
    }
    if (companyData?.userCategory === UserCategory.PARTNER) {
      categories = (companyData.partnerCategories as string[]).map((category) => {
        if (category === PartnersEnum.OTHER && companyData.partnerOtherCategory)
          return <CategTag key={category}>{companyData.partnerOtherCategory}</CategTag>;

        return <CategTag key={category}>{partnerCategories[category]}</CategTag>;
      });
    }

    const separator = t('misc.and');

    if (categories.length) {
      return (
        <>
          {categories.map((category, index) => (
            <>
              {category}
              {index < categories.length - 2 && ', '}
              {index === categories.length - 2 && ` ${separator} `}
            </>
          ))}
        </>
      );
    }

    return categories[0];
  };

  useEffect(() => {
    const setCompany = async (id: string): Promise<void> => {
      const userCertificate = await getUserCertificate(id);
      if (userCertificate === undefined) return;
      if (userCertificate?.registrationDate !== undefined)
        userCertificate.registrationDate = dayjs(
          userCertificate.registrationDate,
          config.dateFormat.universalFormat,
        ).format('DD.MM.YYYY');
      if (userCertificate?.membershipDue !== undefined)
        userCertificate.membershipDue = dayjs(
          userCertificate.membershipDue,
          config.dateFormat.universalFormat,
        ).format('DD.MM.YYYY');
      setCompanyData(userCertificate);
    };
    if (id !== undefined) setCompany(id);
  }, [id]);

  const handleDownload = async (scale: number): Promise<void> => {
    const doc = new JsPDF('l', 'mm', 'a4');
    const elementHTML = document.querySelector('#pdfContent') as HTMLElement;

    const pdfWidth = 297; // A4 width in mm
    const canvas = await html2canvas(elementHTML, {
      scale,
      useCORS: true,
    });

    const imgData = canvas.toDataURL('image/png');
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;
    const ratio = contentHeight / contentWidth;

    const a4Height = pdfWidth * ratio;

    doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, a4Height);
    doc.save('member-certificate.pdf');
  };

  useEffect(() => {
    QRCode.toDataURL(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }${config.routes.memberCertificate.replace(':id', id as string)}?status=1`,
    )
      .then((url) => {
        setQr(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  const isValid =
    companyData &&
    dayjs(companyData?.membershipDue, 'DD.MM.YYYY').format('YYYY.MM.DD') >=
      dayjs().format('YYYY.MM.DD');

  return (
    <div className={styles.container}>
      {status === '1' ? (
        companyData && (
          <Result
            status={isValid ? 'success' : 'warning'}
            title={t(`misc.${isValid ? 'valid' : 'notValid'}CertificateMessage`)
              .replace(':company', companyData?.companyName)
              .replace(
                ':date',
                dayjs(companyData?.membershipDue, 'DD.MM.YYYY').format('YYYY.MM.DD'),
              )}
          />
        )
      ) : (
        <>
          <Row justify={'end'} className={styles.downloadBtnWrap}>
            <Col>
              <Popover
                content={
                  <Space direction='vertical' className={styles.popoverDownload}>
                    <button
                      onClick={() => {
                        handleDownload(2);
                      }}
                      className={styles.download}
                    >
                      <Row justify={'space-around'} align='middle' gutter={5}>
                        <Col>
                          <PaperClipOutlined />
                        </Col>
                        <Col>{t('misc.webQuality')}</Col>
                      </Row>
                    </button>
                    <button
                      onClick={() => {
                        handleDownload(4);
                      }}
                      className={styles.download}
                    >
                      <Row justify={'space-around'} align='middle' gutter={5}>
                        <Col>
                          <PrinterOutlined />
                        </Col>
                        <Col>{t('misc.printQuality')}</Col>
                      </Row>
                    </button>
                  </Space>
                }
                trigger='click'
                placement='bottomRight'
              >
                <button className={styles.download}>
                  <Row justify={'space-around'} align='middle' gutter={5}>
                    <Col>
                      <img src={download} className={styles.downloadImg} alt='' />
                    </Col>
                    <Col>{t('misc.downloadCertificate')}</Col>
                  </Row>
                </button>
              </Popover>
            </Col>
          </Row>
          <div className={styles.pdfContent} id='pdfContent'>
            <div className={styles.content}>
              <Row>
                <Col className={styles.titleWrap}>
                  <div className={styles.logoWrap}>
                    <Logo className={styles.logo} />
                  </div>
                  <h1 className={styles.title}>
                    {`${
                      companyData?.userCategory === UserCategory.MEMBER
                        ? t('misc.membership').toUpperCase()
                        : companyData?.userCategory === UserCategory.PARTNER &&
                          t('misc.partnership').toUpperCase()
                    } ${t('misc.certificate').toUpperCase()}`}
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  className={cx(
                    styles.text,
                    companyData?.userCategory === UserCategory.PARTNER && styles.partnerText,
                  )}
                >
                  {companyData?.userCategory === UserCategory.MEMBER && (
                    <>
                      <Trans
                        i18nKey='common:certificateNsrn.memberDescriptionBeforeServices'
                        components={[
                          <div className={styles.companyName} key={0} />,
                          <i key={1} />,
                          <b key={2} />,
                          <br key={3} />,
                        ]}
                        values={{
                          companyName: companyData?.companyName,
                        }}
                      />
                      {getCategoriesText()}, {}
                      <Trans
                        i18nKey='common:certificateNsrn.memberDescriptionAfterServices'
                        components={[<br key={1} />]}
                      />
                    </>
                  )}
                  {companyData?.userCategory === UserCategory.PARTNER && (
                    <Trans
                      i18nKey='common:certificateNsrn.partnerDescription'
                      components={[
                        <div className={styles.companyName} key={0} />,
                        <b key={1} />,
                        <br key={2} />,
                      ]}
                      values={{
                        companyName: companyData?.companyName,
                      }}
                    />
                  )}
                  <Row className={styles.period} justify='space-between'>
                    <Col className={styles.dateText}>
                      {t('misc.issueDate')}:
                      <br />
                      <b>{companyData?.registrationDate as string}</b>
                    </Col>
                    <Col className={styles.dateText}>
                      {t('misc.validTill')}:
                      <br />
                      <b>{companyData?.membershipDue as string}</b>
                    </Col>
                  </Row>
                  <Row className={styles.signatureWrapper} justify='space-between'>
                    <Col>
                      <Row className={styles.qr} justify='space-between' align={'middle'}>
                        <Col>
                          {qr !== null && <img src={qr} className={styles.qrImg} />}
                          <div className={styles.qrText}>{t('certificate.part9')}</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col className={styles.signatureBox}>
                      <img src={signature} alt='' className={styles.signature} />
                      <div className={styles.signatureText}>
                        {t('certificate.signatureName')}
                        <br />
                        {t('certificate.part10')}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className={styles.footerWrapper}>
              <p className={styles.footerText}>
                New Silk Road Network GmbH, Museumstraße 2-6, 28195 Bremen, Germany
              </p>
              <img
                className={styles.footer}
                src={nsrnCertificateFooter}
                alt='certificate-footer-image'
              />
            </div>
            <img
              className={styles.background}
              src={nsrnCertificateBack}
              alt='certificate-background'
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MemberCertificate;
